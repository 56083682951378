import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker } from '@react-google-maps/api';
import * as Styled from 'jobDescriptionMap_styled';

const JobDescriptionMap = (props) => {
  const { t } = useTranslation('jobDescription');
  return (
    <Styled.MapContainer>
      <Styled.SectionHeader>{t('location')}</Styled.SectionHeader>
      <Styled.MapLocationText>
        {props.jobDescription && props.jobDescription.address}
      </Styled.MapLocationText>

      <GoogleMap
        mapContainerStyle={{ height: '300px', maxHeight: '300px' }}
        center={{
          lat:
            (props.jobDescription && props.jobDescription.latitude) || 40.73061,
          lng:
            (props.jobDescription && props.jobDescription.longitude) ||
            -73.935242,
        }}
        zoom={16}
      >
        <Marker
          position={{
            lat:
              (props.jobDescription && props.jobDescription.latitude) ||
              40.73061,
            lng:
              (props.jobDescription && props.jobDescription.longitude) ||
              -73.935242,
          }}
        />
      </GoogleMap>
    </Styled.MapContainer>
  );
};

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
});

JobDescriptionMap.propTypes = {
  jobDescription: PropTypes.object, // contains data about current active job.
};

export default connect(mapStateToProps, {})(JobDescriptionMap);
